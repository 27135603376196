import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import Navbar from "../components/navbar-nonhome";
import { css } from "@emotion/react";
import BackgroundImage from "gatsby-background-image";
import { Helmet } from "react-helmet";
import {
	Trans,
	Link,
	I18nextContext,
	useTranslation,
} from "gatsby-plugin-react-i18next";

export default function BlogList({ data, pageContext }) {
	{
		const { t } = useTranslation();
		const context = React.useContext(I18nextContext);
		const taal = context.language;
		const posts = data.allWpPost.edges;
		const { currentPage, numPages } = pageContext;
		const isFirst = currentPage === 1;
		const isLast = currentPage === numPages;
		const prevPage = currentPage - 1 === 1 ? "/" : (currentPage - 1).toString();
		const nextPage = (currentPage + 1).toString();

		return (
			<Layout>
				<Helmet
					titleTemplate="%s | Asbitech"
					htmlAttributes={{
						lang: `${taal}`,
					}}
				>
					<title>{t("Nieuws")}</title>
					<meta
						name="description"
						content="Ontdek het laatste nieuws over Asbitech en projectverslagen over asbestvrije werven"
					/>
				</Helmet>
				<Navbar></Navbar>

				<div className="headerpadding" />
				<div
					className="mobile"
					css={css`
						max-width: 1000px;
						margin: auto auto;
						padding-bottom: 4rem;
					`}
				>
					<div
						css={css`
							font-size: 2.8rem;
							line-height: 3.2rem;
							font-weight: 500;
							color: var(--text-color);
							padding-top: 2rem;
							padding-bottom: 2rem;
						`}
					>
						<Trans>Nieuws</Trans>
					</div>

					{posts.map(({ node }) => {
						const title = node.title || node.slug;
						var categoriestyle = "nieuws";
						if (node.nieuws.categorie === "nieuws") {
							categoriestyle = "nieuws";
						} else {
							categoriestyle = "project";
						}
						if (node.nieuws.categorie === "nieuws" && taal === "nl") {
							var content = "nieuws";
						}
						if (node.nieuws.categorie === "nieuws" && taal === "fr") {
							var content = "actualités";
						}
						if (node.nieuws.categorie === "project" && taal === "nl") {
							var content = "project";
						}
						if (node.nieuws.categorie === "project" && taal === "fr") {
							var content = "projet";
						}

						return (
							<div
								css={css`
									display: flex;
									width: 100%;
									min-height: 200px;
									border-bottom: 2px solid var(--footer-color);
									padding-bottom: 2rem;
									padding-top: 2rem;
								`}
								key={node.slug}
							>
								<div
									css={css`
										width: 25%;
										margin-right: 2rem;
									`}
									style={{
										backgroundImage: `url(${node.nieuws.afbeeldingsgallerij[0].localFile.publicURL})`,
										backgroundPosition: "center center",
										backgroundSize: "cover",
									}}
								>
									<Link to={`/nieuws/${node.slug}`}>
										<div
											css={css`
												width: 100%;
												height: 100%;
											`}
										/>
									</Link>
								</div>

								<div
									css={css`
										width: 75%;
									`}
								>
									<div
										css={css`
											margin-bottom: 0.5rem;
										`}
									>
										<span className={categoriestyle}>{content}</span>
									</div>
									<div className="bloglisttitle">
										<Link to={`/nieuws/${node.slug}`}>{title}</Link>
									</div>
									<div
										css={css`
											padding-bottom: 1rem;
										`}
									>
										{node.nieuws.korteInleiding}
									</div>
									<Link
										className="tekstbutton-yellow"
										to={`/nieuws/${node.slug}`}
									>
										<Trans>Lees Meer</Trans>
									</Link>
								</div>
							</div>
						);
					})}
					<div
						css={css`
							text-align: right;
							padding-top: 2rem;
						`}
					>
						{Array.from({ length: numPages }, (_, i) => (
							<Link
								className="pagebutton"
								activeClassName="activepage"
								key={`pagination-number${i + 1}`}
								to={`/nieuws${i === 0 ? "" : "/" + (i + 1)}`}
							>
								{i + 1}
							</Link>
						))}
					</div>
				</div>
			</Layout>
		);
	}
}
export const blogListQuery = graphql`
	query ($skip: Int!, $limit: Int!, $language: String!) {
		locales: allLocale(filter: { language: { eq: $language } }) {
			edges {
				node {
					ns
					data
					language
				}
			}
		}
		allWpPost(
			filter: { language: { locale: { eq: $language } } }
			sort: { fields: date, order: DESC }
			limit: $limit
			skip: $skip
		) {
			edges {
				node {
					id
					nieuws {
						korteInleiding
						categorie
						afbeeldingsgallerij {
							altText
							localFile {
								publicURL
								childImageSharp {
									fluid(quality: 100) {
										...GatsbyImageSharpFluid_withWebp
									}
								}
							}
						}
					}
					slug
					title
					uri
				}
			}
		}
	}
`;
